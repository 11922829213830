const header = document.querySelector('header')

// Add scrolled class when page scrolled
const trigger = document.getElementById('header-scroll-observer')
const triggerObserver = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      header.classList.remove('header-scrolled')
    } else {
      header.classList.add('header-scrolled')
    }
  })
}, {
  rootMargin: "40px"
})
triggerObserver.observe(trigger)