const is_touch_enabled = () => {
  return ( 'ontouchstart' in window ) ||
         ( navigator.maxTouchPoints > 0 ) ||
         ( navigator.msMaxTouchPoints > 0 )
}

const subHeaderButton = document.getElementsByClassName('subheader-button');

let openedTab;
let clickedButton;

const unAssignedTab = () => {
  openedTab.classList.remove('open');
  openedTab = null;
  clickedButton = null
}

const handleClick = (e) => {
  const element = e.target;
  if (!clickedButton || element != clickedButton) {
    e.preventDefault();
  }
  
  if (openedTab) {
    unAssignedTab();
  }

  element.nextElementSibling.classList.add('open')
  openedTab = element.nextElementSibling;
  clickedButton = element;
}

if (is_touch_enabled()) {
  for (var i = 0; i < subHeaderButton.length; i++) {
    subHeaderButton[i].addEventListener("click", (e) => {  
      handleClick(e)
    });
  }
  
  document.body.addEventListener("click", (e) => {
    if (openedTab) {
      if(!e.target.closest("#desktop-navbar")) {
        unAssignedTab()
      };
    }
  });  
}