const mobileHeader = document.getElementById('mobile-header');
const mobileHeaderClose = document.getElementById('mobile-hedaer-close-btn');
const mobileHeaderOpen = document.getElementById('mobile-header-open');
const mobileMenuContent = document.getElementById('mobile-menu-content');

const mobileMenuBack = document.getElementById('mobile-menu-back');
const mobileMenuHeader = document.getElementsByClassName('mobile-menu-header');

const load_mobile_search = async () => {
  const ReactDOM = await import('react-dom/client');
  const mobileRoot = ReactDOM.createRoot(
    document.getElementById('mobile-search-node')
  );

  const { default: algolia } = await import('../algolia');
  mobileRoot.render(algolia);
};

// Load mobile search
mobileHeaderOpen.addEventListener(
  'click',
  () => {
    load_mobile_search();
  },
  { once: true }
);

// Open Mobile Header
mobileHeaderOpen.addEventListener('click', () => {
  document.body.style.overflow = 'hidden';
  mobileHeader.classList.add('open');
});

// Close Mobile Header
mobileHeaderClose.addEventListener('click', () => {
  mobileMenuContent.style.cssText = `
    transition: all 0.4s;
    transform: translateX(0);
  `;
  document.body.style.overflow = 'scroll';
  mobileHeader.classList.remove('open');
  setTimeout(() => {
    document
      .querySelectorAll('.mobile-menu-category-content')
      .forEach((sidebarCategory) => {
        sidebarCategory.style.zIndex = '0';
      });
  }, 400);
});

// Menu Category sub-menu
for (let i = 0; i < mobileMenuHeader.length; i++) {
  mobileMenuHeader[i].addEventListener('click', () => {
    document.getElementById(
      mobileMenuHeader[i].dataset.sidebarCategory
    ).style.zIndex = '100';

    document.getElementById(
      mobileMenuHeader[i].dataset.sidebarCategory
    ).firstElementChild.scrollTop = '0';

    mobileMenuContent.style.cssText = `
        transition: all 0.4s;
        transform: translateX(-100%);
      `;
  });
}

// Menu Category Back-to-Main-Menu
mobileMenuBack.addEventListener('click', () => {
  mobileMenuContent.style.cssText = `
    transition: all 0.4s;
    transform: translateX(0);
  `;
  setTimeout(() => {
    document
      .querySelectorAll('.mobile-menu-category-content')
      .forEach((sidebarCategory) => {
        sidebarCategory.style.zIndex = '0';
      });
  }, 400);
});
